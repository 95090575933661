/* App.css */

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('background_image.jpg');
  background-size: cover;
  background-position: center;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* ... (Previous CSS code remains unchanged) */

.text {
  font-size: 4rem;
  font-weight: bold;
  text-align: center;
  white-space: nowrap; /* Ensure the text doesn't wrap */
  overflow: hidden; /* Hide overflowing text */
  animation: typingAnimation 4s steps(40) forwards; /* Adjust animation duration as needed */
  color: rgba(21, 249, 5, 0.5);
  text-shadow: 2px 2px 4px black;
  font-family: 'Roboto Mono', monospace; /* Futuristic code font */
}

@keyframes typingAnimation {
  from {
    width: 0;
  }
  to {
    color: white; /* Change the color to make text visible */
    width: 100%;
  }
}

.visible {
  animation: none; /* Disable animation when the text is visible */
}

/* App.css */

/* ... (Previous CSS code remains unchanged) */

/* Media query for smaller screens (e.g., mobile devices) */
@media screen and (max-width: 768px) {
  .App {
    height: auto; /* Adjust height for smaller screens */
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Set height to cover the entire viewport height */
    background-image: url('background_image.jpg');
    background-size: cover;
    background-position: center;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto; /* Adjust height for smaller screens */
    padding: 20px; /* Add padding for better readability */
  }

  .text {
    font-size: 2rem; /* Decrease font size for better fit */
    text-align: center;
    white-space: normal; /* Allow text to wrap */
    overflow: visible; /* Show overflowing text */
    animation: none; /* Disable typing animation for smaller screens */
    color: white; /* Set text color to be visible */
    text-shadow: 2px 2px 4px black;
    font-family: 'Roboto Mono', monospace; /* Futuristic code font */
  }
}
